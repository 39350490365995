.form-login {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: auto;
    margin: 6rem 5rem;
}

.accedi {
    margin: 5rem;
}
.menu {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.crea-oroscopo {
    display: flex;
    flex-direction: column;
    justify-content: start;
    margin: 2rem;
}

@media only screen and (max-width: 600px) {
    .accedi {
        margin: 1rem;
    }
    .form-login {
        margin: 1rem;
    }
    .crea-oroscopo {
        margin: 1rem;
    }
}
