.ctaOroscopo {
    background-color: #fff;
    color: #030163;
    margin: 20px;
    padding: 15px;
    border-radius: 15px;
    font-size: 23px;
}   
.ctaOroscopoNewYear {
    background-color: gold;
    color: #030163;
}

.btnLogin, .btnInvia {
    background-color: #fff;
    color: #030163;
    margin: 15px;
    margin-top: 30px;
    padding: 15px;
    border-radius: 15px;
    width: 40%;
}

.btnInvia {
    margin: 1rem auto;
    margin-top: 2rem;
}


.btnMenu {
    margin: 1rem auto;
    padding: 20px;
    font-size: 18px;
    background-color: #fff;
    border-radius: 15px;
}


.btnLogout {
    background-color:crimson;
}
.btnElimina {
    background-color: crimson;
    font-size: 12px;
    margin: 1rem;
    padding: 5px 8px;
}


button {
    padding: 15px 25px;
    border: unset;
    border-radius: 15px;
    color: #212121;
    z-index: 1;
    background: #e8e8e8;
    position: relative;
    font-weight: 1000;
    font-size: 17px;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
    overflow: hidden;
}

button::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 0;
    border-radius: 15px;
    background-color: #212121;
    z-index: -1;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms
}

button:hover {
    color: #e8e8e8;
    cursor: pointer;
}

button:hover::before {
    width: 100%;
}

@media only screen and (max-width: 600px) {
    .btnLogin {
        width: 80%;
    }

    .btnMenu {
        width: 100%;
    }
}