.daily {
    margin: 5rem;
}
li {
    list-style: none;
    /*box-shadow: 0 0 15px 4px rgba(0, 0, 0, 0.06);*/
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    border: 1px solid #edecff;
    border-radius: 15px;
    margin-bottom: 2rem;
    background-color: #edecff;
}
.iconaSegno {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin: 1rem;
}
.segno {
    margin: 0 1rem;
    font-weight: bold;
}

.stelle {
    margin-left: auto;
    margin-right: 1rem;
    font-weight: bold;
}

.divOroscopo {
    margin: 0 1rem 1rem 1rem;
    padding: 0 1rem 1rem 1rem;
}

@media only screen and (max-width: 600px) {
    h1, h4, li, .btnDaily {
        margin: 1rem;
    }
    .daily {
        margin: 0rem;
    }
}