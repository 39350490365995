.info-div {
    margin: 1rem;
    padding: 10px 1rem;
}

.info-lista {
    font-size: 14px;
    padding: 4px 0;;
}

.info-flex {
    display: flex;
    flex-direction: column;
}