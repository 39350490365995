
.App-header {
    background-color: #282c34;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.App-link {
    color: #61dafb;
}

.news {
    color: rgb(0, 42, 255);
    background-color: gold;
    font-family: Papyrus, fantasy;
    font-size: 24px;
    font-style: normal;
    font-variant: normal;
    font-weight: 900;
}
@keyframes App-logo-spin {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}

html,
body {
    margin: 0;
    padding: 0;
    font-size: 18px;
}

body {
    margin: 0;
    padding: 0;
    width: 100vw;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
}

.App {
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    background-color: #cccbfe;
}

.homepage {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin: 0 0 2rem 0;
}




