input, select, textarea {
    padding: 15px 25px;
    border: unset;
    border-radius: 15px;
    color: #212121;
    z-index: 1;
    background: #f8f8f8;
    position: relative;
    font-weight: 1000;
    font-size: 17px;
    -webkit-box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    box-shadow: 4px 8px 19px -3px rgba(0, 0, 0, 0.27);
    transition: all 250ms;
    overflow: hidden;
}

select {
    padding: 10px;
    width: 50%;
}

select.descrizione {
    font-size: 25px;
}

textarea {
    overflow: auto;
}
.username, .password {
    border-radius: 15px;
    padding: 15px;
    width: 50%;
    font-size: 1rem;
}



@media only screen and (max-width: 600px) {
   .username, .password {
    width: 100%;
    padding: 10px;
    font-size: 1.2rem;
   }


}