footer {
    font-size: 10px;
    background-color: #030163;
    text-align: center;
    padding: 5px;
    color: #fff;
    margin-top: auto;
    bottom: 0;
}   

.link-footer, .link-footer:hover {
    color: #fff;
}