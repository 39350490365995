.link {
  margin: 0 1rem;
  padding: 10px;
  width: 40px;
  color:#fff;
  text-decoration: none;
  font-weight: 800;
}
.link:hover {
  color:black;
  background-color: #fff;
  border-radius: 15px;
}

nav {
    background-color: #aba9fe;
    padding: 20px 25px;
    display: flex;
    flex-direction: row;
    align-items: center;
    flex-wrap: wrap;
    justify-content: space-around;
}

